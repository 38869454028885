<template>
  <!-- eslint-disable max-len -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.779"
    height="13.462"
    viewBox="0 0 11.779 13.462"
    class="notes"
  >
    <g transform="translate(-32)">
      <g transform="translate(32)">
        <path
          class="a"
          d="M42.938,0H33.4a.842.842,0,0,0-.841.841v1.4h-.28a.28.28,0,1,0,0,.561h2.244a.28.28,0,1,0,0-.561h-.79a.84.84,0,1,1,.79,1.122H32.561V5.048h-.28a.28.28,0,1,0,0,.561h2.244a.28.28,0,1,0,0-.561h-.79a.84.84,0,1,1,.79,1.122H32.561V7.853h-.28a.28.28,0,1,0,0,.561h2.244a.28.28,0,1,0,0-.561h-.79a.84.84,0,1,1,.79,1.122H32.561v1.683h-.28a.28.28,0,1,0,0,.561h2.244a.28.28,0,1,0,0-.561h-.79a.84.84,0,1,1,.79,1.122H32.561v.841a.842.842,0,0,0,.841.841h9.536a.842.842,0,0,0,.841-.841V.841A.842.842,0,0,0,42.938,0Z"
          transform="translate(-32)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Notes',
};
</script>

<style lang="scss">
  .notes {
    .a {
      fill: currentColor;
      opacity: 1;
    }
  }
</style>
